import EventEmmitter from "./EventEmitter"

class Storage extends EventEmmitter {
  getString(name) {
    return localStorage.getItem(name)
  }
  setString(name, value) {
    localStorage.setItem(name, value)
    this.emit(name, value)
    return value
  }
  getObject(name) {
    const str = localStorage.getItem(name)
    return str ? JSON.parse(str) : undefined
  }
  setObject(name, value) {
    localStorage.setItem(name, JSON.stringify(value))
    this.emit(name, value)
    return value
  }
  unset(name) {
    this.setString(name, undefined)
  }
}

const storage = new Storage()
export default storage
